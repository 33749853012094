import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as api from '@dki/api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { of } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, retryWhen, take, withLatestFrom } from 'rxjs/operators';

import { formatDateISO } from '../../utils/functions';
import { MyRestaurantsSelectors } from '../my-restaurants';
import { RootStoreState } from '../state';
import * as ReportsActions from './actions';

@Injectable()
export class ReportsEffects {
	getHourlySales = createEffect(() =>
		this._actions.pipe(
			ofType(ReportsActions.getHourlySales),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurantsIds))),
			filter(([, selectedRestaurantsIds]) => !!selectedRestaurantsIds && !!selectedRestaurantsIds[0]),
			mergeMap(([a, selectedRestaurantsIds]) =>
				this._reportsApiClient
					.hourlySalesV1ReportsHourlySalesGet(selectedRestaurantsIds, null, null, formatDateISO(a.from.startOf('day').toISO()), formatDateISO(a.to.endOf('day').toISO()))
					.pipe(
						map(
							(hourlySales) => ReportsActions.getHourlySalesSuccess({ hourlySales }),
							catchError((error: HttpErrorResponse) => of(ReportsActions.getHourlySalesFail({ error })))
						)
					)
			)
		)
	);

	getOperatingReport = createEffect(() =>
		this._actions.pipe(
			ofType(ReportsActions.getOperatingReport),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurantsIds))),
			filter(([, selectedRestaurantsIds]) => !!selectedRestaurantsIds && !!selectedRestaurantsIds[0]),
			mergeMap(([a, selectedRestaurantsIds]) =>
				this._reportsApiClient
					.getOperatingReportV1ReportsOperatingGet(selectedRestaurantsIds, null, null, formatDateISO(a.from.startOf('day').toISO()), formatDateISO(a.to.endOf('day').toISO()))
					.pipe(
						map(
							(operatingReport) => ReportsActions.getOperatingReportSuccess({ operatingReport }),
							catchError((error: HttpErrorResponse) => of(ReportsActions.getOperatingReportFail({ error })))
						)
					)
			)
		)
	);

	getWeeklyReport = createEffect(() =>
		this._actions.pipe(
			ofType(ReportsActions.getWeeklyReport),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurantsIds))),
			filter(([, selectedRestaurantsIds]) => !!selectedRestaurantsIds && !!selectedRestaurantsIds[0]),
			mergeMap(([a, selectedRestaurantsIds]) => {
				const dt = a.weekNumber;
				return this._reportsApiClient
					.getWeeklyReportV1ReportsWeeklyGet(selectedRestaurantsIds, null, null, formatDateISO(dt.startOf('week').toISO()), formatDateISO(dt.endOf('week').toISO()))
					.pipe(
						map(
							(weeklyReport) => ReportsActions.getWeeklyReportSuccess({ weeklyReport }),
							catchError((error: HttpErrorResponse) => of(ReportsActions.getWeeklyReportFail({ error })))
						)
					);
			})
		)
	);

	getProductsReport = createEffect(() =>
		this._actions.pipe(
			ofType(ReportsActions.getProductsReport),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurantsIds))),
			filter(([, selectedRestaurantsIds]) => !!selectedRestaurantsIds && !!selectedRestaurantsIds[0]),
			mergeMap(([a, selectedRestaurantsIds]) =>
				this._reportsApiClient
					.getProductReportV1ReportsProductsGet(selectedRestaurantsIds, null, null, formatDateISO(a.from.startOf('day').toISO()), formatDateISO(a.to.endOf('day').toISO()))
					.pipe(
						map(
							(productsReport) => ReportsActions.getProductsReportSuccess({ productsReport }),
							catchError((error: HttpErrorResponse) => of(ReportsActions.getProductsReportFail({ error })))
						)
					)
			)
		)
	);

	getEmployeeMealsReport = createEffect(() =>
		this._actions.pipe(
			ofType(ReportsActions.getEmployeeMealsReport),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurantsIds))),
			filter(([, selectedRestaurantsIds]) => !!selectedRestaurantsIds && !!selectedRestaurantsIds[0]),
			mergeMap(([a, selectedRestaurantsIds]) =>
				this._reportsApiClient
					.getEmployeeMealsReportV1ReportsEmployeeMealsGet(
						selectedRestaurantsIds,
						null,
						null,
						formatDateISO(a.from.startOf('day').toISO()),
						formatDateISO(a.to.endOf('day').toISO())
					)
					.pipe(
						map(
							(employeeMealsReport) => ReportsActions.getEmployeeMealsReportSuccess({ employeeMealsReport }),
							catchError((error: HttpErrorResponse) => of(ReportsActions.getEmployeeMealsReportFail({ error })))
						)
					)
			)
		)
	);

	getDiscountReport = createEffect(() =>
		this._actions.pipe(
			ofType(ReportsActions.getDiscountsReport),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurantsIds))),
			filter(([, selectedRestaurantsIds]) => !!selectedRestaurantsIds && !!selectedRestaurantsIds[0]),
			mergeMap(([a, selectedRestaurantsIds]) =>
				this._reportsApiClient
					.getDiscountsReportV1ReportsDiscountsGet(selectedRestaurantsIds, null, null, formatDateISO(a.from.startOf('day').toISO()), formatDateISO(a.to.endOf('day').toISO()))
					.pipe(
						map(
							(discountsReport) => ReportsActions.getDiscountsReportSuccess({ discountsReport }),
							catchError((error: HttpErrorResponse) => of(ReportsActions.getEmployeeMealsReportFail({ error })))
						)
					)
			)
		)
	);

	getDttReport = createEffect(() =>
		this._actions.pipe(
			ofType(ReportsActions.getDTTReport),
			delay(333),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurantsIds))),
			mergeMap(([a, selectedRestaurantsIds]) => {
				return this._reportsApiClient
					.getSosDttReportV1ReportsDttGet(
						selectedRestaurantsIds,
						new Set(a.aggregation),
						new Set(a.weekday),
						a.source,
						null,
						null,
						formatDateISO(a.from.startOf('day').toISO()),
						formatDateISO(a.to.endOf('day').toISO())
					)
					.pipe(
						map(
							(dttReport) => ReportsActions.getDTTReportSuccess({ dttReport }),
							catchError((error: HttpErrorResponse) => of(ReportsActions.getDTTReportFail({ error })))
						)
					);
			})
		)
	);

	getFinancialMovements = createEffect(() =>
		this._actions.pipe(
			ofType(ReportsActions.getFinancialMovements),
			delay(333),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			mergeMap(([a, selectedRestaurantsIds]) => {
				return this._reportsApiClient
					.getSafeTransactionsReportV1ReportsSafeTransactionsGet(
						[selectedRestaurantsIds],
						null,
						null,
						formatDateISO(a.from.startOf('day').toISO()),
						formatDateISO(a.to.endOf('day').toISO())
					)
					.pipe(
						map(
							(movements) => ReportsActions.getFinancialMovementsSuccess({ movements }),
							catchError((error: HttpErrorResponse) => of(ReportsActions.getFinancialMovementsFail({ error })))
						)
					);
			})
		)
	);

	getWeeklyVouchersReport = createEffect(() =>
		this._actions.pipe(
			ofType(ReportsActions.getVouchersReport),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			mergeMap(([a, selectedRestaurantsIds]) => {
				return this._reportsApiClient
					.getWeeklyVouchersReportV1ReportsWeeklyVouchersGet(
						[selectedRestaurantsIds],
						null,
						null,
						formatDateISO(a.from.startOf('day').toISO()),
						formatDateISO(a.to.endOf('day').toISO())
					)
					.pipe(
						map(
							(vouchersReport) => ReportsActions.getVouchersReportSuccess({ vouchersReport }),
							catchError((error: HttpErrorResponse) => of(ReportsActions.getVouchersReportFail({ error })))
						)
					);
			})
		)
	);

	constructor(private _actions: Actions, private _reportsApiClient: api.ReportsApiClient, private _store: Store<RootStoreState>) {}
}
