import { HttpErrorResponse } from '@angular/common/http';
import * as api from '@dki/api-client';
import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

import { STORE_SLICE_KEY } from './state';

export const getHourlySales = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Hourly Sales`, props<{ from: DateTime; to: DateTime }>());
export const getHourlySalesSuccess = createAction(`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Hourly Sales`, props<{ hourlySales: api.HourlySales }>());
export const getHourlySalesFail = createAction(`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Hourly Sales`, props<{ error: HttpErrorResponse }>());

export const getOperatingReport = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Operating Report`, props<{ from: DateTime; to: DateTime }>());
export const getOperatingReportSuccess = createAction(`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Operating Report`, props<{ operatingReport: api.OperatingReport }>());
export const getOperatingReportFail = createAction(`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Operating Report`, props<{ error: HttpErrorResponse }>());

export const getWeeklyReport = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Weekly Report`, props<{ weekNumber: DateTime }>());
export const getWeeklyReportSuccess = createAction(`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Weekly Report`, props<{ weeklyReport: api.WeeklyReport }>());
export const getWeeklyReportFail = createAction(`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Weekly Report`, props<{ error: HttpErrorResponse }>());

export const getProductsReport = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Products Report`, props<{ from: DateTime; to: DateTime }>());
export const getProductsReportSuccess = createAction(`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Products Report`, props<{ productsReport: api.ProductReport }>());
export const getProductsReportFail = createAction(`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Products Report`, props<{ error: HttpErrorResponse }>());

export const getEmployeeMealsReport = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Employee Meals Report`, props<{ from: DateTime; to: DateTime }>());
export const getEmployeeMealsReportSuccess = createAction(
	`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Employee Meals Report`,
	props<{ employeeMealsReport: api.EmployeeMealReport }>()
);
export const getEmployeeMealsReportFail = createAction(`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Employee Meals Report`, props<{ error: HttpErrorResponse }>());

export const getDiscountsReport = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Discount Report`, props<{ from: DateTime; to: DateTime }>());
export const getDiscountsReportSuccess = createAction(`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Discount Report`, props<{ discountsReport: api.DiscountsReport }>());
export const getDiscountsReportFail = createAction(`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Discount Report`, props<{ error: HttpErrorResponse }>());

export const getDTTReport = createAction(
	`[ ${STORE_SLICE_KEY} / GET ] -> DTT Report`,
	props<{ aggregation: api.DkiBeClickhouseAnalyticsSosDttReportAggregation[]; weekday: api.WeekDay[]; from: DateTime; to: DateTime; source: api.Source }>()
);
export const getDTTReportSuccess = createAction(`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> DTT Report`, props<{ dttReport: api.SosDttReport[] }>());
export const getDTTReportFail = createAction(`[ ${STORE_SLICE_KEY} / GET FAIL ] -> DTT Report`, props<{ error: HttpErrorResponse }>());

export const getFinancialMovements = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Financial Movements Report`, props<{ from: DateTime; to: DateTime }>());
export const getFinancialMovementsSuccess = createAction(`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Financial Movements Report`, props<{ movements: api.SafeTransaction[] }>());
export const getFinancialMovementsFail = createAction(`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Financial Movements Report`, props<{ error: HttpErrorResponse }>());

export const getVouchersReport = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Vouchers Report`, props<{ from: DateTime; to: DateTime }>());
export const getVouchersReportSuccess = createAction(`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Vouchers Report SUCCESS`, props<{ vouchersReport: api.WeeklyVouchersReport }>());
export const getVouchersReportFail = createAction(`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Vouchers Report Fail`, props<{ error: HttpErrorResponse }>());
