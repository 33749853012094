import { HttpErrorResponse } from '@angular/common/http';
import * as api from '@dki/api-client';
import { DateTime } from 'luxon';

export const STORE_SLICE_KEY = 'Reports';

export interface ReportsState {
	data?: {
		hourlySales: api.HourlySales;
		operatingReport: api.OperatingReport;
		weeklyReport: api.WeeklyReport;
		productsReport: api.ProductReport;
		employeeMealsReport: api.EmployeeMealReport;
		discountsReport: api.DiscountsReport;
		dttReport: api.SosDttReport[];
		financialMovements: api.SafeTransaction[];
		vouchersReport: api.WeeklyVouchersReport;
	};
	status: {
		hourlySales?: { isLoading: boolean; dateFrom: DateTime; dateTo: DateTime; error?: HttpErrorResponse };
		operatingReport?: { isLoading: boolean; dateFrom: DateTime; dateTo: DateTime; error?: HttpErrorResponse };
		weeklyReport?: { isLoading: boolean; weekNumber: DateTime; error?: HttpErrorResponse };
		productsReport?: { isLoading: boolean; dateFrom: DateTime; dateTo: DateTime; error?: HttpErrorResponse };
		employeeMealsReport?: { isLoading: boolean; dateFrom: DateTime; dateTo: DateTime; error?: HttpErrorResponse };
		discountsReport?: { isLoading: boolean; dateFrom: DateTime; dateTo: DateTime; error?: HttpErrorResponse };
		dttReport?: { isLoading: boolean; dateFrom: DateTime; dateTo: DateTime; error?: HttpErrorResponse };
		financialMovements?: { isLoading: boolean; dateFrom: DateTime; dateTo: DateTime; error?: HttpErrorResponse };
		vouchersReport?: { isLoading: boolean; dateFrom: DateTime; dateTo: DateTime; error?: HttpErrorResponse };
	};
}

export const REPORTS_INITIAL_STATE: ReportsState = {
	status: {}
};
